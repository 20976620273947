<template>
  <v-container>

    <NavBar :step="2"></NavBar>

    <Titulo></Titulo>

    <br>
    <br>
      


    <v-stepper :class="vSteperStyle" v-model="e1" elevation="0">

      <v-stepper-header style="box-shadow: none">
        <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Plan </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3"> Mascotas </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> Datos </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col :class="vista">
                  <br v-if="screen > 800">
                  <h3 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C; margin-top: -5%;">
                    Ingresa tus datos</h3>
                  <h4 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                    Son necesarios para completar la póliza del seguro</h4>

                  <br>

                  <v-text-field v-model="userData.name" @change="change()" :rules="nameRules" label="Nombre" required
                    maxlength="51" solo filled disabled style="box-sizing: border-box;  height: 57px; margin:auto;
          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
          border-radius: 6px;"></v-text-field>

                  <!-- <br> -->
                  <br>

                  <v-text-field v-model="userData.surname" @change="change()" :rules="surnameRules" label="Apellido"
                    maxlength="51" required solo filled disabled style="box-sizing: border-box;  height: 57px; margin:auto;
          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
          border-radius: 6px;"></v-text-field>

                  <!-- <br> -->
                  <br>

                  <v-text-field v-model="userData.email" @change="change()" :rules="emailRules" label="Email" required
                    maxlength="51" solo filled style="box-sizing: border-box;  height: 57px; margin:auto;
          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
          border-radius: 6px;"></v-text-field>

                  <!-- <br> -->
                  <br>

                  <v-row v-if="screen < 800">

                    <v-col>
                      <v-select :rules="monthRules" style="max-width: 85px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="months" v-model="userData.month" :loading="loadingMonths"
                        v-on:change="getDays()" item-text="name" label="Mes" return-object required solo
                        filled></v-select>
                    </v-col>

                    <v-col>
                      <v-select :rules="dayRules" style="max-width: 85px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="days" v-model="userData.day" :loading="loadingDays"
                        :disabled="loadingDays || userData.month === null" item-text="name" label="Día" return-object
                        required solo filled>
                      </v-select>
                    </v-col>

                    <v-col>
                      <v-select :rules="yearRules" style="max-width: 85px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" value="2024" :items="years" v-model="userData.year" item-text="name"
                        label="Año" return-object required solo filled @change="changeBisiesto()"></v-select>
                    </v-col>

                  </v-row>

                  <v-row v-else>

                    <v-col>
                      <v-select :rules="monthRules" style="max-width: 160px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="months" v-model="userData.month" :loading="loadingMonths"
                        v-on:change="getDays()" item-text="name" label="Mes" return-object required solo
                        filled></v-select>
                    </v-col>

                    <v-col>
                      <v-select :rules="dayRules" style="max-width: 160px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="days" v-model="userData.day" :loading="loadingDays"
                        :disabled="loadingDays || userData.month === null" item-text="name" label="Día" return-object
                        required solo filled>
                      </v-select>
                    </v-col>

                    <v-col>
                      <v-select :rules="yearRules" style="max-width: 160px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" value="2024" :items="years" v-model="userData.year" item-text="name"
                        label="Año" return-object required solo filled @change="changeBisiesto()"></v-select>
                    </v-col>

                  </v-row>

                  <!-- <br> -->
                  <br>

                  <v-text-field v-model="userData.phoneNumber" @change="change()" :rules="phoneRules" prefix="+569"
                    :counter="8" maxlength="8" label="Teléfono" required solo filled style="box-sizing: border-box;  height: 57px; margin:auto;
          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
          border-radius: 6px;"></v-text-field>

                  <!-- <br> -->
                  <br>

                  <v-text-field v-model.lazy="newRut" @change="change()" :rules="rutRules" maxlength="13"
                    label="Ingresa tu RUT ( sin punto y con guión)" required solo filled style="box-sizing: border-box;  height: 57px; margin:auto;
          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
          border-radius: 6px;" @input="changeRut"></v-text-field>

                  <!-- <br> -->
                  <br>

                  <v-text-field v-model="userData.serialNumber" @change="change()" :rules="serialNumberRules"
                    maxlength="10" label="Ingresa el número de Documento / Serie" required solo filled style="box-sizing: border-box;  height: 57px; margin:auto;
          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
          border-radius: 6px;">
                  </v-text-field>

                  <!-- <br> -->
                  <br>

                  <v-text-field v-model="userData.address" @change="change()" :rules="addressRules" maxlength="51"
                    label="Ingresar nombre de calle" required solo filled style="box-sizing: border-box;  height: 57px; margin:auto;
          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
          border-radius: 6px;"></v-text-field>

                  <!-- <br> -->
                  <br>

                  <div v-if="screen > 800" class="number-address">
                    <v-text-field class="labelsDatos-address" v-model="userData.addressNumber"
                      :rules="NumberRulesAddress" maxlength="51" label="Ingresa tu número de calle" required filled solo
                      @change="change()"></v-text-field>
                    <v-text-field class="labelsDatos-address" v-model="userData.addressDepto" :rules="NumberRulesAddress2"
                     maxlength="51" label="Ingresa tu número de depto" required filled solo @change="change()"></v-text-field>
                  </div>

                   <div v-if="screen <= 800" class="number-address">
                    <v-text-field class="labelsDatos-address" v-model="userData.addressNumber"
                      :rules="NumberRulesAddress" maxlength="51" label="Número de calle" required filled solo
                      @change="change()"></v-text-field>
                    <v-text-field class="labelsDatos-address" v-model="userData.addressDepto" :rules="NumberRulesAddress2"
                     maxlength="51" label="Número de depto" required filled solo @change="change()"></v-text-field>
                  </div>

                  <!-- <br> -->
                  <br>

                  <v-select :items="regions" v-model="userData.region" @change="change()" :rules="regionRules"
                    :loading="loadingRegions" v-on:change="getCommunes()" item-text="name"
                    label="Selecciona tu región de residencia" required return-object solo filled style="box-sizing: border-box;  height: 57px; margin:auto;
          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
          border-radius: 6px;"></v-select>

                  <!-- <br> -->
                  <br>

                  <v-select :items="communes" v-model="userData.commune" :rules="communeRules" @change="change()"
                    :loading="loadingCommunes"  item-text="name"
                    label="Selecciona tu comuna de residencia" required return-object solo filled style="box-sizing: border-box;  height: 57px; margin:auto;
          background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
          border-radius: 6px;">
                  </v-select>

                  <br>
                  <br>

                  <div class="button-container">
                    <v-btn
                      style="background-color: #2D387C; color: white; border-radius: 12px; width: 100%; height: 120%;"
                      @click="validate()">
                      Continuar
                    </v-btn>

                    <br>

                    <v-btn v-if="screen > 800" style="margin-left: 40%; margin-top: 2%" text :to="{ name: `Step2` }">
                      Atras </v-btn>
                    <v-btn v-else style="margin-left: 30%; margin-top: 2%" text :to="{ name: `Step2` }"> Atras
                    </v-btn>

                  </div>

                </v-col>

              </div>

              <!-- <v-row style="justify-content: center; margin-top: 1rem">
        <v-btn text @click="back()"> Volver </v-btn>
      </v-row> -->
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>

import CountryService from "@/services/country.service.js";
import RutUtils from "@/utils/rut.utils.js";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
import ShieldService from "../services/shield.service";
import NavBar from "@/components/NavBar.vue";
import Titulo from "../components/Titulo.vue";
import swal from 'sweetalert';

// import CountryData from "@/utils/country-data.js";

import CryptoJS from 'crypto-js'

export default {
  name: "Step4",
  components: { NavBar, Titulo },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    screen: window.screen.width,
    vSteperStyle: '',
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      address: null,
      addressNumber: null,
      addressDepto: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      dates: null,
      day: null,
      month: null,
      year: null,
      stepId: null,
      coverType: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    petData: {
      cantPet: 1,
      dataPet: [
          {
          species: null,
          breed: null,
          breedCode: null,
          petName: null,
          age: null,
          sex: null,
          hasMicrochip: false,
          panel: 0,
          breeds: null,
        }
      ],
    },
    // petData: {
    //   species: null,
    //   breed: null,
    //   breedCode: null,
    //   petName: null,
    //   age: null,
    //   sex: null,
    //   hasMicrochip: false,
    // },
    planData: {
      couponId: null,
      plan: null,
    },
    loadingRegions: false,
    loadingCommunes: false,
    TipoPlan: null,
    e1: 4,
    // month: null,
    // day: null,
    // year: null,
    months: [],
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    days: ["Selecciona el mes"],
    yars: [],
    valid: false,
    addressRules: [
      (v) => !!v || "Debes ingresar tu dirección",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
      (v) => !(/^\s+$/.test(v))  || "Debes ingresar tu dirección",
      (v) => /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s]+$/.test(v) || "Ingrese solo números y letras"
    ],
    regionRules: [(v) => !!v || "Ingresa la region en la que vives con tu mascota",],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives",],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
      (v) => /^[0-9kK.-]+$/.test(v) || 'No se permiten carácteres especiales',
      (v) => (v && v.length >= 11 && v.length <= 12) || "Rut no válido",
    ],
    serialNumberRules: [
      (v) => !!v || "El número de serie es requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
    ],
    NumberRulesAddress: [
      (v) => !!v || "N° de calle requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
    ],
    NumberRulesAddress2: [
      (v) => !v || /^[a-zA-Z0-9]+$/.test(v) || "Ingrese solo números y letras",
      (v) => !v || (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres",
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    regions: [],
    communes: [],
    newRut: "",
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "No se permite un valor vacío",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => !(/^\s+$/.test(v)) && v.trim() !== '' || "No se permite un valor vacío",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    dayRules: [
      (v) => !!v || "El día es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ]
  }),
  async mounted() {

    this.getRegions();
    this.getMonths();
    this.getYears();

    const query = this.$route.query;

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.petData = abandoned.petData;
      this.planData = abandoned.planData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const petDataLocal = JSON.stringify(this.petData);
      const planDataLocal = JSON.stringify(this.planData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
      localStorage.setItem('petData', encryptedPetDataLocal);
      localStorage.setItem('planData', encryptedPlanDataLocal);

      this.getCommunes();

      if (this.userData.rut) {
        this.newRut = this.userData.rut;
      }
      if (this.userData.dates) {
        this.getDaysMounted();
      }

    } else {
      const userDataAux = localStorage.getItem('userData');
      const petDataAux = localStorage.getItem('petData');
      const servicesDataAux = localStorage.getItem('servicesData');
      const planDataAux = localStorage.getItem('planData');

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);

      this.getCommunes();

      if (this.userData.rut) {
        this.newRut = this.userData.rut;
      }
      if (this.userData.dates) {
        this.getDaysMounted();
      }
      // console.log("userData: ", this.userData);

      const bytesPetData = CryptoJS.AES.decrypt(petDataAux, this.secretKey);
      const decryptedDataPet = bytesPetData.toString(CryptoJS.enc.Utf8);
      this.petData = JSON.parse(decryptedDataPet);
      // console.log("petData: ", this.petData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
      const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
      this.planData = JSON.parse(decryptedDataPlan);
      // console.log("planData: ", this.planData);
    }

    if(this.screen > 800 ) {
      this.vSteperStyle = 'vSteperStyle';
    } else {
      this.vSteperStyle = 'vSteperStyle2';
    }

    if (this.screen > 800) {
      this.vista = 'vista2'
      this.TipoPlan = 'PlanEscritorio'
      // this.addressNumber = 'Ingresa tu número de calle'
      // this.addressDepto = 'Ingresa tu número de depto'
    } else {
      this.vista = 'vista'
      this.TipoPlan = 'PlanMovil'
      // this.addressNumber = 'Número de calle'
      // this.addressDepto = 'Número de depto'
    }
  },
  methods: {
    // async getPlans() {
    //   await ShieldService.getPlans(
    //     this.planData.couponId,
    //     this.petData.age.quantity,
    //     this.petData.age.unit
    //   ).then((response) => {
    //     response.forEach(element => {
    //       if (element._id == this.planData) {
    //         this.planData = element
    //       }
    //     });
    //   })
    // },
    async changeBisiesto() {
      if (this.userData.month && this.userData.year && this.days && this.userData.month.name == 'Febrero' && !this.isBisiesto(this.userData.year.id) && this.days.length == 29) {
        this.userData.day = null;
        this.days = await dateService.getDays(
          this.userData.month.name, false
        );
      } else if (this.userData.month && this.userData.year && this.days && this.userData.month.name == 'Febrero' && this.isBisiesto(this.userData.year.id) && this.days.length == 28) {
        this.days = await dateService.getDays(
          this.userData.month.name, true
        );
      } else {
        this.days = await dateService.getDays(
          this.userData.month.name, null
        );
      }
    },
    isBisiesto(anio) {
      return (anio % 4 === 0 && anio % 100 !== 0) || (anio % 400 === 0);
    },
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        // this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    async validate() {
      if (this.$refs.form.validate()) {

        try {

          const fechaActual = new Date();
          var newDate = this.userData.year.id + "-" + this.userData.month.number + "-" + this.userData.day.id
          var diff = fechaActual.getFullYear() - this.userData.year.id
          if (diff < 18) {
            swal({
              title: "Atención!",
              text: "Para contratar este seguro, usted debe ser mayor de edad!",
              icon: "info",
              button: "De acuerdo",
            });
          } else {
            this.userData.dates = newDate

            var setVar = false;

            const userDataLocal = JSON.stringify(this.userData);
            const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
            localStorage.setItem('userData', encryptedUserDataLocal);

            if (this.activeCampaingUse == 'TRUE') {

              const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

              if (activeCampaingRes.data.data.meta.total != '0') {
                this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;
                setVar = true;
              } else {
                this.servicesData.contactID = null;
              }

              const shieldRes = await ShieldService.updateContactActiveCampain(this.userData, this.planData, this.petData, this.servicesData, 'datos contratante');

              this.servicesData.contactID = setVar ? shieldRes.data.data.contact.id : shieldRes.data.data.contacts[0].id;

              const servicesDataLocal = JSON.stringify(this.servicesData);
              const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
              localStorage.setItem('servicesData', encryptedServicesDataLocal);

            }

            this.$router.push({ path: '/mt/resumen' });

          }

        } catch (error) {
          this.$router.push({ path: '/mt/error' })
        }
      }

    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.userData.month) {
        this.loadingDays = true;
        this.userData.day = null;
        this.changeBisiesto();
        this.loadingDays = false;
      }
    },
    async getDaysMounted() {
      if (this.userData.month) {
        this.loadingDays = true;
        this.changeBisiesto();
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    async change() {

      try {
        if (this.userData.year && this.userData.month && this.userData.day) {
          const fechaActual = new Date();
          var newDate = this.userData.year.id + "-" + this.userData.month.number + "-" + this.userData.day.id
          var diff = fechaActual.getFullYear() - this.userData.year.id
          if (diff > 18) {
            this.userData.dates = newDate;
          }
        }

        await ShieldService.newCreateStep(this.userData, null, null, this.servicesData, 'Datos personales');

      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-date {
  align-items: center;
  display: flex;
  margin-left: 0rem;
}

.number-address {
  display: flex;
  gap: 10px;
}

.labelsDatos-address {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.v-input.v-input--is-disabled.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed.v-select div.v-input__control div.v-text-field__details div.v-messages.theme--light div.v-messages__wrapper div.v-messages__message {
  color: red !important;
}
</style>