<template>
  <v-container style="margin:auto">

    <NavBar :step="1"></NavBar>

    <Titulo></Titulo>

    <br>
    <br>

    <v-col>

      <v-stepper :class="vSteperStyle" v-model="e1" elevation="0">

        <v-stepper-header style="box-shadow: none">

          <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2"> Plan </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 3" step="3"> Mascotas </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4"> Datos </v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="2">

            <v-form ref="form" v-model="valid" @submit.prevent="validate2">

              <div class="step-data">

                <br>
                <br>

                <h3 class="text-center tituloView">Estos son nuestros planes</h3>
                <p></p>
                <h4 class="text-center SubTituloView">
                  para proteger {{ petData.cantPet }} mascotas</h4>
                <br>



                <!-- <v-row>
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                  <v-text-field :class="labelDescuento" v-model="planData.couponId" :rules="couponRules"
                    @blur="deteleSpace()" label="Ingresa aquí tu código de descuento" maxlength="51" solo
                    @keyup.enter="SendCouponID"></v-text-field>

                  <v-btn filled solo
                    style="width:70px; height: 50px; border-radius: 0px 10px 10px 0px; font-family: Helvetica; font-style: normal; font-weight: 700;
                      font-size: 14px; line-height: 143.34%; background-color:#FFB600; color:white; text-transform: capitalize ;"
                    @click="SendCouponID">Aplicar</v-btn>

                </v-row> -->

              </div>

              <br v-if="screen < 800">
              <br>

              <div v-if="screen > 800 && loadingPlans == false && tipoPlan == false" class="plan-container">
                <PlanEscritorio v-for=" (plan, indice) in plans" :planSenior="planSenior" :key="plan._id"
                  :planData="plan" :planDataAux="planData" :servicesData="servicesData" :index="indice"
                  :tipoPlan="tipoPlan" :userData="userData" @next-step="validate" />
              </div>

              <div v-if="screen > 800 && loadingPlans == false && tipoPlan == true" class="plan-container">
                <PlanEscritorio v-for=" (plan, indice) in planSenior" :key="plan._id" :planData="plan" :index="indice"
                  :planDataAux="planData" :servicesData="servicesData" :tipoPlan="tipoPlan" :userData="userData"
                  @next-step="validate" />
              </div>

              <div v-if="screen <= 800 && loadingPlans == false" class="plan-container">
                <PlanMovil :planData="plans" :planSenior="planSenior" :tipoPlan="tipoPlan" @next-step="validate"
                  :planDataAux="planData" :servicesData="servicesData" :loadingPlans="loadingPlans"
                  :userData="userData" />
              </div>

              <br v-if="screen > 800">
              <br v-if="screen > 800">
              <br v-if="screen > 800">

              <h3 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em;
                    color: #2D387C;">¿Cuáles planes quieres elegir para tus mascotas?</h3>

              <br>
              <br>

              <br v-if="screen > 800">

              <div v-if="screen > 800" class="step-data" align-center r>

                <v-row style="display: flex; align-items: center; justify-content: center;" class="text-center">

                  <v-radio-group v-model="petData.autoSelect">

                    <div class="custom-radio2">
                      <label style="max-width:150px; font-weight: bold; color: #2E3A8C; text-align: left;">
                        Ingresa el nombre de tu mascota
                      </label>
                    </div>

                    <div class="custom-radio2" style="display: flex; flex-direction: column; align-items: center;">
                      <label>Plan S</label>
                      <v-radio @click="selectedPlans(0)" value="Plan S" label="Elegir este plan para todos"></v-radio>
                    </div>

                    <div class="custom-radio2" style="display: flex; flex-direction: column; align-items: center;">
                      <label>Plan M</label>
                      <v-radio @click="selectedPlans(1)" value="Plan M" label="Elegir este plan para todos"></v-radio>
                    </div>

                    <div class="custom-radio2" style="display: flex; flex-direction: column; align-items: center;">
                      <label>Plan L</label>
                      <v-radio @click="selectedPlans(2)" value="Plan L" label="Elegir este plan para todos"></v-radio>
                    </div>

                  </v-radio-group>

                </v-row>

                <br>
                <br>
                <br>

                <div class="step-data">

                  <v-row class="text-center" style="display: flex; flex-direction: column; ">

                    <v-col v-for=" (pet, indice) in petData.dataPet" :key="pet._id" :index="indice">
                      <v-row>

                        <v-row justify="center" :key="index">

                          <v-text-field v-model="pet.petName" :placeholder="`Nombre Mascota ${indice + 1}`"
                            maxlength="51" class="text-field" solo required :rules="mascotaRules">
                          </v-text-field>

                          <v-col>
                            <v-radio-group :rules="selectRules" v-model="pet.selectPlan" row
                              @change="calculate(indice)">
                              <div style="display: flex; margin: auto;">
                                <v-radio class="custom-radio" value="Plan S">
                                </v-radio>
                                <v-radio class="custom-radio" value="Plan M">
                                </v-radio>
                                <v-radio class="custom-radio" value="Plan L">
                                </v-radio>
                              </div>
                            </v-radio-group>
                          </v-col>

                        </v-row>

                        <br>
                        <br>
                        <br>
                        <br>

                      </v-row>

                    </v-col>

                  </v-row>

                </div>

              </div>


              <!-- PARA movil -->
              <div v-if="screen <= 800" class="text-center" style="font-size:14px;">Ingresa el nombre de
                tus mascotas y elige su plan
              </div>

              <br>

              <div v-if="screen <= 800" align-center: justify-center>

                <div v-for=" (pet, indice) in petData.dataPet" :key="pet._id" :index="indice">

                  <v-col>

                    <v-text-field style="margin: auto;" v-model="pet.petName"
                      :placeholder="`Nombre Mascota ${indice + 1}`" maxlength="51" class="text-field" solo required
                      :rules="mascotaRules">
                    </v-text-field>

                    <br>

                    <v-radio-group :rules="selectRules" v-model="pet.selectPlan" row @change="calculate(indice)">

                      <div style="display: flex; flex-wrap: nowrap; align-items: center; margin:auto">

                        <v-col class="plan-container-movil"
                          style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                          <div style="font-size:14px; margin-bottom: 8px;">
                            Plan S
                          </div>
                          <div style="display: flex; justify-content: center;">
                            <v-radio style="margin-left: 50%" value="Plan S"></v-radio>
                          </div>
                        </v-col>

                        <v-col class="plan-container-movil"
                          style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                          <div style="font-size:14px; margin-bottom: 8px;">
                            Plan M
                          </div>
                          <div style="display: flex; justify-content: center;">
                            <v-radio style="margin-left: 50%" value="Plan M"></v-radio>
                          </div>
                        </v-col>

                        <v-col class="plan-container-movil"
                          style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                          <div style="font-size:14px; margin-bottom: 8px;">
                            Plan L
                          </div>
                          <div style="display: flex; justify-content: center;">
                            <v-radio style="margin-left: 50%" value="Plan L"></v-radio>
                          </div>
                        </v-col>

                      </div>

                    </v-radio-group>

                  </v-col>

                 

                </div>

              </div>

            

              <br>

              <router-link to="/ruta-destino" class="step-data" style="text-decoration: none; color: #1a2b70; "
                @click="back()">
                <div class="icon-text-container">
                  <img src="../assets/images/lapiz.png" alt="icono de lápiz" class="icono">
                  <span>Modificar cantidad de mascotas</span>
                </div>
              </router-link>

              <br>

              <div v-if="screen > 800" class="step-data cuadradoValorPlan">

                <div class="cuadradoValorPlanContenido">

                  <div>

                    <h2 class="total" style="color: #2D387C; text-align: left; font-weight: 700; margin-right:200px">Total mensual: {{
                      formatPrice(planData.total) }} (UF {{ planData.totalUf.toFixed(2) }} mensual)</h2>

                    <p class="details" style="text-align: left;">{{ petData.cantPet }} Mascotas aseguradas</p>

                  </div>

                  <button class="continue-button" @click="validate2()" style="margin-left: 20px;">CONTINUAR</button>

                </div>

              </div>


              <div v-else class="step-data cuadradoValorPlanMovil">

                <div class="cuadradoValorPlanMovilContenido">

                  <h2 class="totalMovil">Total mensual: {{ formatPrice(planData.total) }} </h2>

                  <h2 class="totalMovilUF">(UF {{ (planData.totalUf.toFixed(2)) }} mensual)</h2>

                </div>

                <button class="continue-button" @click="validate2()">CONTINUAR</button>

              </div>

            </v-form>

          </v-stepper-content>

        </v-stepper-items>

      </v-stepper>

      <v-row style="justify-content: center; margin-top: 1rem">
        <!-- <v-btn color="primary" @click="validate()"> Siguiente </v-btn> -->
        <v-btn text :to="{ name: 'Step2' }"> Atras </v-btn>

      </v-row>

    </v-col>

  </v-container>

</template>

<script>
import PlanEscritorio from "@/components/PlanEscritorio.vue";
import PlanMovil from "@/components/PlanMovil.vue";
import NavBar from "@/components/NavBar.vue";
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';
import Titulo from "../components/Titulo.vue";

import CryptoJS from 'crypto-js'

export default {
  name: "PlanForm",
  components: { PlanEscritorio, PlanMovil, NavBar, Titulo },
  data: () => ({
    valid: false,
    checked: [], // Array para almacenar el estado de cada checkbox
    secretKey: process.env.VUE_APP_CRYPT,
    plans: [],
    planSenior: [],
    e1: 2,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    loadingPlans: true,
    screen: window.screen.width,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      address: null,
      addressNumber: null,
      addressDepto: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      dates: null,
      day: null,
      month: null,
      year: null,
      stepId: null,
      coverType: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'update',
    },
    petData: {
      cantPet: 1,
      autoSelect: false,
      dataPet: [
        {
          species: null,
          breed: null,
          breedCode: null,
          petName: null,
          age: null,
          sex: null,
          hasMicrochip: false,
          breeds: null,
          plan: null,
          selectPlan: null,
          expansionPanel: 0,
        }
      ],
    },
    planData: {
      total: 0,
      totalUf: 0,
      couponId: null,
      plan: null,
    },
    labelDescuento: '',
    tipoPlan: false,
    couponRules: [
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres",
      (v) => !!v || 'El código de descuento es requerido.',
      (v) => (v && !/\s/.test(v)) || 'El código de descuento no puede contener espacios en blanco.'
    ],
    mascotaRules: [
      (v) => !!v || "El nombre es requerido",
      // (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s-]+$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    selectRules: [
      (v) => !!v || "El plan es obligatorio",
    ]
  }),
  async mounted() {

    const petDataAux = localStorage.getItem('petData');
    const userDataAux = localStorage.getItem('userData');
    const planDataAux = localStorage.getItem('planData');
    //const servicesDataAux = localStorage.getItem('servicesData');
    if (petDataAux) {
      const bytesPetData = CryptoJS.AES.decrypt(petDataAux, this.secretKey);
      const decryptedDataPet = bytesPetData.toString(CryptoJS.enc.Utf8);
      this.petData = JSON.parse(decryptedDataPet);
      // console.log("petData: ", this.petData);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      // const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      // const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      // this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      if (planDataAux) {
        const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
        const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
        this.planData = JSON.parse(decryptedDataPlan);
        // console.log("planData: ", this.planData);
      }
    }

    if (this.screen > 800) {
      this.labelDescuento = 'labelDescuento'
    } else {
      this.labelDescuento = 'labelDescuento2'
    }
    // this.petData.age = localStorage.getItem('ageQ')
    // this.petData.unit = localStorage.getItem('ageU')
    this.getPlans();
    this.calculate(null, true);
  },
  methods: {
    selectedPlans(indexPlan) {

      this.petData.dataPet.forEach(pet => {
        pet.selectPlan = this.petData.autoSelect;
        pet.plan = this.plans[indexPlan];
      });

      this.calculate(null, true);
    },
    calculate(indice, sender) {

      if (!sender) {

        this.petData.autoSelect = null;

        var planS;
        var planM;
        var planL;

        this.plans.forEach(plan => {
          if (plan.name == 'Plan S') {
            planS = plan;
          } else if (plan.name == 'Plan M') {
            planM = plan;
          } else if (plan.name == 'Plan L') {
            planL = plan;
          }
        });

        if (this.petData.dataPet[indice].selectPlan == 'Plan S') {
          this.petData.dataPet[indice].plan = planS;
        } else if (this.petData.dataPet[indice].selectPlan == 'Plan M') {
          this.petData.dataPet[indice].plan = planM;
        } else if (this.petData.dataPet[indice].selectPlan == 'Plan L') {
          this.petData.dataPet[indice].plan = planL;
        }
      }

      let total = 0;
      let totalUf = 0;
      this.petData.dataPet.forEach(pet => {
        if (pet.plan) {
          total = pet.plan.priceCLP + total;
          totalUf = pet.plan.price + totalUf;
        }
      });
      this.planData.total = total;
      this.planData.totalUf = totalUf;
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    deteleSpace() {
      this.planData.couponId = this.planData.couponId.replace(/\s/g, '');
    },
    async SendCouponID() {
      var couponId = this.planData.couponId.trim();
      if (this.$refs.form.validate()) {
        var plansResponse = await ShieldService.getPlans( //Espera el código ingresado en el step 1
          couponId,
          this.petData.age.quantity,
          this.petData.age.unit
        );
        if (plansResponse.length != 0 && plansResponse.status != 'error') {
          swal({
            title: "¡Código válido!",
            text: "El descuento se ha aplicado exitosamente.",
            icon: "success",
            button: "De acuerdo",
          });

          this.plans = plansResponse
          var aux = ['', '', ''];
          this.plans.forEach(plan => {
            if (plan.name === 'Plan Lite') {
              aux[0] = plan
            } else if (plan.name === 'Plan Básico') {
              aux[1] = plan
            } else if (plan.name === 'Plan Plus') {
              aux[2] = plan
            } else {
              this.tipoPlan = true
              this.planSenior[0] = plan
            }
          });
          if (this.planSenior[0]) {
            this.plans = this.planSenior[0]
          } else {
            this.plans = aux
          }
          this.loadingPlans = false;



          this.$forceUpdate();
        } else {
          this.planData.couponId = null
          swal({
            title: "Código no válido",
            text: "Lamentablemente no existen planes asociados a este código de descuento. Intenta con otro código.",
            icon: "info",
            button: "Ok",
          });
        }
      }
    },
    validate2() {

      if (this.$refs.form.validate()) {

        const planDataLocal = JSON.stringify(this.planData);
        const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
        localStorage.setItem('planData', encryptedPlanDataLocal);

        const petDataLocal = JSON.stringify(this.petData);
        const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
        localStorage.setItem('petData', encryptedPetDataLocal);

        this.$router.push({ path: '/mt/mascotas' })

      }

    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    back() {
      this.$router.push({ path: '/mt/' })
    },
    async getPlans() {
      this.loadingPlans = true;
      // this.petData.age = localStorage.getItem('ageQ')
      // this.petData.unit = localStorage.getItem('ageU')
      this.plans = await ShieldService.getPlans(this.planData.couponId, 6, 'M', this.petData.cantPet);
      var aux = ['', '', ''];
      this.plans.forEach(plan => {
        if (plan.name === 'Plan S') {
          aux[0] = plan
        } else if (plan.name === 'Plan M') {
          aux[1] = plan
        } else if (plan.name === 'Plan L') {
          aux[2] = plan
        } else {
          this.tipoPlan = true
          this.planSenior[0] = plan
        }
      });
      this.plans = aux
      this.petData.dataPet.forEach(element => {
        if(element.plan && element.plan.name == "Plan S"){
          element.plan = this.plans[0];
        } else if(element.plan && element.plan.name == "Plan M"){
          element.plan = this.plans[1];
        } else if(element.plan && element.plan.name == "Plan L"){
          element.plan = this.plans[2];
        }
      });
      this.calculate(null, true);
      this.loadingPlans = false;
    },
  },
};
</script>


<style lang="scss">
.icon-text-container {
  display: flex;
  align-items: center;
}

.icon-text-container img {
  margin-right: 8px;
  width: 20px;
  height: auto;
}

.icon-text-container span {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  color: #1a2b70;
}

.hidden-radio .v-input--selection-controls__input {
  display: none;
}

@media (max-width: 1500px) {
  .cuadradoValorPlan {
    min-height: 120px;
    margin: auto;
    background-color: #DAE2FA;
    border-radius: 10px;
    max-width: 80%;
  }
}

@media (min-width: 1500px) {
.cuadradoValorPlan {
    min-height: 120px;
    margin: auto;
    background-color: #DAE2FA;
    border-radius: 10px;
    max-width: 50%;
  }
} 

.cuadradoValorPlanContenido {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

.cuadradoValorPlanMovil {
  align-items: center;
  justify-content: center;
  background-color: #eef1fc;
  border-radius: 12px;
  padding: 20px 30px;
  width: fit-content;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  margin: auto;
}

.cuadradoValorPlanMovilContenido {
  text-align: center;
  min-width: 200px;
}

.totalMovil {
  font-size: 18px;
  font-weight: bold;
  color: #2D387C;
  margin: 0;
}

.totalMovilUF {
  font-size: 15px;
  font-weight: bold;
  color: #2D387C;
  margin: 0;
}

.tituloView {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.SubTituloView {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
}

.plan-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.plan-container-movil {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  background-color: #f3f4fe;
  border-radius: 10px;
  border: 1px solid #e0e4ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  color: #27367f;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  margin-right: 20px;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.labelDescuento2 {
  min-width: 30%;
  max-width: 62%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.titulo {
  background-image: url("~@/assets/images/titulo2.png");
  max-width: 350px;
  height: 150px;
  margin: auto;
}

.custom-link {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #1f2a63;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
}

.custom-link:hover {
  text-decoration: none;
}

.custom-link::after {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #1f2a63;
  margin-top: 4px;
}

.planes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  background-color: #f4f7ff;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px
}

.container-total {
  align-items: center;
  justify-content: center;
  background-color: #eef1fc;
  border-radius: 12px;
  padding: 20px 30px;
  width: fit-content;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  margin-right: 25%;
  margin-left: 25%;
}

.total {
  font-size: 20px;
  font-weight: bold;
  color: #2D387C;
  margin: 0;
}

.details {
  font-size: 16px;
  color: #2e3a8c;
  margin-top: 5px;
}

.continue-button {
  background-color: #2e3a8c;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.continue-button:hover {
  background-color: #27367a;
  text-align: center;
}

.header-text {
  color: #333;
  font-size: 1em;
  font-weight: normal;
  font-family: Arial, sans-serif;
  margin: 0;
  text-align: center;
}

.custom-radio {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 210px;
  height: 50px;
  background-color: #f4f7ff;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: -5%;
}

.custom-radio2 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 50px;
  background-color: #f4f7ff;
  border-radius: 10px;
  padding: 10px;
  margin-right: 30px;
  margin-left: 10px;
}

.radio-button {
  align-self: center;
  size: 20px;
}

.custom-radio input[type="radio"] {
  width: 100px;
  height: 20px;
  margin-right: 5px;
}

.custom-radio label {
  font-size: 14px;
  color: #333;
}

.custom-checkbox label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-checkbox input[type="checkbox"]:checked+label {
  background-color: #5c67f2;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: center;
}

.text-field {
  border: 1px solid #6685a4;
  max-width: 180px;
  height: 50px;
}

.text-field2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 40px;
  background-color: white;
  border: 2px solid #3b3e87;
  border-radius: 10px;
  font-size: 18px;
  color: #7a7ea3;
  text-align: center;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: border-color 0.2s ease-in-out;
  margin-bottom: 10px;
}
</style>